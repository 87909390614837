import React, { useState, memo, useEffect } from 'react';
import { subscribeUser, getLatestChallenge } from '../services/api';
import logo from '../assets/logo.png';
import '../styles/SignupPage.css';
import CountdownTimer from '../components/CountdownTimer';
import { FaTwitter, FaFacebook, FaShare } from 'react-icons/fa';

// Separate memoized form component
const SubscriptionForm = memo(({ onSubmit, isLoading, error }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit} className="signup-form">
      <div className="form-container">
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter your email"
          required
          className="email-input"
          autoComplete="email"
          spellCheck="false"
        />
        <button 
          type="submit" 
          disabled={isLoading}
          className="submit-button"
        >
          {isLoading ? 'JOINING...' : 'ACCEPT THE CHALLENGE'}
        </button>
      </div>
      {error && <div className="error-message">{error}</div>}
      <p className="signup-note">Effortless signup - just enter your email to get started.</p>
      <p className="signup-note">Trusted by athletes worldwide.</p>
      <p className="privacy-note"><i>Your privacy matters. Unsubscribe anytime.</i></p>
    </form>
  );
});

// Memoized success message component
const SuccessMessage = memo(({ handleShare }) => (
  <div className="success-container">
    <div className="success-animation">
      <div className="checkmark">✓</div>
    </div>
    <h2 className="welcome-message">Welcome to the Tribe!</h2>
    <div className="next-steps">
      <div className="step">
        <div className="step-icon">📧</div>
        <p>Check your inbox for a welcome email</p>
      </div>
      <div className="step">
        <div className="step-icon">🎯</div>
        <p>Your first challenge arrives soon</p>
      </div>
      <div className="step">
        <div className="step-icon">🏅</div>
        <p>Get ready to earn your first badge</p>
      </div>
    </div>
    <CountdownTimer className="success-countdown" />
    <div className="social-share">
      <p>Share your commitment with others:</p>      
      <div className="share-buttons">
          <button onClick={() => handleShare('twitter')} className="share-button twitter">
            <FaTwitter /> Share on Twitter
          </button>
          <button onClick={() => handleShare('facebook')} className="share-button facebook">
            <FaFacebook /> Share on Facebook
          </button>
          <button onClick={() => handleShare('general')} className="share-button share">
            <FaShare /> Share
          </button>
        </div>
    </div>
  </div>
));

function SignupPage() {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [latestChallenge, setLatestChallenge] = useState(null);
  const [isChallengeLoading, setIsChallengeLoading] = useState(true);
  const [challengeError, setChallengeError] = useState(null);

  useEffect(() => {
    const fetchLatestChallenge = async () => {
      setIsChallengeLoading(true);
      setChallengeError(null);
      try {
        const challenge = await getLatestChallenge();
        setLatestChallenge(challenge);
      } catch (err) {
        console.error('Failed to fetch latest challenge:', err);
        setChallengeError('Unable to load the latest challenge. Please try refreshing the page.');
      } finally {
        setIsChallengeLoading(false);
      }
    };

    fetchLatestChallenge();
  }, []);

  const handleSubmit = async (email) => {
    setIsLoading(true);
    setError('');

    try {
      const response = await subscribeUser(email);
      if (response.userHash) {
        localStorage.setItem('userHash', response.userHash);
      }
      setIsSubscribed(true);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to subscribe. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleShare = async (platform) => {
    const baseUrl = 'https://www.momentumchallenges.com';
    const text = `Join me on Momentum! I've just signed up and I'm ready to take on challenges! 🏆`;
    const shareUrl = `${baseUrl}`;

    let shareLink;
    switch (platform) {
      case 'twitter':
        shareLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(shareUrl)}`;
        break;
      case 'facebook':
        shareLink = `https://www.facebook.com/dialog/share?app_id=1107643947701312&display=popup&href=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(text)}&hashtag=%23MomentumChallenges`;
        break;
      case 'general':
        if (navigator.share) {
          try {
            await navigator.share({
              title: 'Join Momentum',
              text: text,
              url: shareUrl
            });
          } catch (err) {
            if (err.name !== 'AbortError') {
              await navigator.clipboard.writeText(`${text}\n${shareUrl}`);
              alert('Share text copied to clipboard!');
            }
          }
          return;
        } else {
          await navigator.clipboard.writeText(`${text}\n${shareUrl}`);
          alert('Share text copied to clipboard!');
          return;
        }
      default:
        return;
    }

    window.open(shareLink, '_blank', 'width=600,height=400');
  };

  return (
    <div className="signup-container" role="main">
      <div className="animated-background">
        <div className="gradient-overlay"></div>
        <div className="geometric-shapes">
          <div className="shape shape-1"></div>
          <div className="shape shape-2"></div>
          <div className="shape shape-3"></div>
        </div>
      </div>
      
      <div className="signup-content">
        <div className="signup-header" role="banner">
          <div className="logo-container">
            <img src={logo} alt="Momentum" className="logo-image" />
          </div>
          <h1>MOMENTUM</h1>
          <div className="tagline-container">
            <p className="tagline">Unlock Your</p>
            <p className="highlight">Peak Performance</p>
          </div>
          <p className="subtitle">
            Become Part of an Exclusive Community of Athletes Pushing Boundaries and Crushing Goals
          </p>
        </div>


        <div className="cta-section" aria-live="polite">
          {!isSubscribed && <CountdownTimer />}
          {isSubscribed ? (
            <SuccessMessage handleShare={handleShare} />
          ) : (
            <SubscriptionForm 
              onSubmit={handleSubmit}
              isLoading={isLoading}
              error={error}
            />
          )}
        </div>

        {!isSubscribed && (
          <div className="badge-vault-preview">
            <h2 className="section-title">Build Your Legacy</h2>
            <div className="badge-vault-content">
              <div className="badge-vault-left">
                <div className="badge-grid">
                  <div className="badge-preview-card gold">
                    <div className="badge-glow"></div>
                    <img src="/badges/gold-sample.png" alt="Gold Badge Example" />
                    <span>Gold</span>
                  </div>
                  <div className="badge-preview-card silver">
                    <div className="badge-glow"></div>
                    <img src="/badges/silver-sample.png" alt="Silver Badge Example" />
                    <span>Silver</span>
                  </div>
                  <div className="badge-preview-card bronze">
                    <div className="badge-glow"></div>
                    <img src="/badges/bronze-sample.png" alt="Bronze Badge Example" />
                    <span>Bronze</span>
                  </div>
                </div>
              </div>
              <div className="badge-vault-right">
                <p>Each challenge conquered earns you a one-of-a-kind emblem of honor, a testament to your relentless drive and warrior spirit. These legendary badges are yours to claim and display in your sacred Badge Vault—a visual saga of your strength, endurance, and unyielding commitment to the tribe. Forge your legacy, one badge at a time.</p>
                <div className="badge-features">
                  <div className="badge-feature">
                    <span className="feature-icon">🏆</span>
                    <span>Unlock exclusive badges</span>
                  </div>
                  <div className="badge-feature">
                    <span className="feature-icon">📈</span>
                    <span>Track your unstoppable progress</span>
                  </div>
                  <div className="badge-feature">
                    <span className="feature-icon">🌟</span>
                    <span>Display your triumphs as a symbol of your strength</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!isSubscribed && (
          isChallengeLoading ? (
            <div className="current-challenge-section loading">
              <div className="loading-spinner">Loading latest challenge...</div>
            </div>
          ) : challengeError ? (
            <div className="current-challenge-section error">
              <div className="error-message">{challengeError}</div>
            </div>
          ) : latestChallenge && (
            <div className="current-challenge-section">
              <h4 className="challenge-title" style={{ color: '#fff' }}>Current Challenge:</h4>
              <h2>{latestChallenge.title}</h2>
              {latestChallenge.imageUrl && (
                <div className="challenge-image-wrapper">
                  <img 
                    src={latestChallenge.imageUrl} 
                    alt={latestChallenge.title}
                    className="challenge-image"
                  />
                </div>
              )}
              
              <div className="challenge-levels">
                <div className="challenge-level bronze">
                  <h4>🥉 Bronze</h4>
                  <p>{latestChallenge.bronzeDescription}</p>
                </div>
                
                <div className="challenge-level silver">
                  <h4>🥈 Silver</h4>
                  <p>{latestChallenge.silverDescription}</p>
                </div>
                
                <div className="challenge-level gold">
                  <h4>🥇 Gold</h4>
                  <p>{latestChallenge.goldDescription}</p>
                </div>
              </div>
            </div>
          )
        )}

        {!isSubscribed && (
          <div className="features-grid">
            <div className="feature-card">
              <div className="feature-icon workout">🏋️‍♂️</div>
              <h3>Progressive Challenges</h3>
              <p>Take on dynamic, weekly challenges designed to elevate your skills and test your limits</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon medal">🏅</div>
              <h3>Achievement Badges</h3>
              <p>Earn exclusive badges that symbolize your hard work, commitment, and progress</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon levels">🏆</div>
              <h3>Designed for All Levels</h3>
              <p>Embark on our Bronze, Silver, or Gold challenges and push your limits at a pace that suits you. Each level offers unique rewards and opportunities to elevate your fitness to new heights.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon fire">🔥</div>
              <h3>Challenge Streaks</h3>
              <p>Build a winning streak by completing consecutive challenges! Stay consistent to unlock special rewards</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SignupPage; 
