import React, { useState } from 'react';
import { deleteChallenge } from '../services/api';
import '../styles/ChallengeList.css';
import ErrorBoundary from '../components/ErrorBoundary';
import PropTypes from 'prop-types';

function ChallengeList({ challenges, onChallengeDeleted }) {
  const [deletingIds, setDeletingIds] = useState(new Set());

  const formatDate = (dateString) => {
    try {
      return new Date(dateString).toLocaleString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      console.error('Invalid date string:', dateString);
      return 'Invalid date';
    }
  };

  const handleDelete = async (challengeId) => {
    if (window.confirm('Are you sure you want to delete this challenge?')) {
      try {
        setDeletingIds(prev => new Set([...prev, challengeId]));
        await deleteChallenge(challengeId);
        if (onChallengeDeleted) {
          onChallengeDeleted();
        }
      } catch (error) {
        console.error('Failed to delete challenge:', error);
        alert('Failed to delete challenge. Please try again.');
      } finally {
        setDeletingIds(prev => {
          const newSet = new Set(prev);
          newSet.delete(challengeId);
          return newSet;
        });
      }
    }
  };

  // Separate challenges into upcoming and expired
  const now = new Date();
  const { upcoming, expired } = challenges.reduce((acc, challenge) => {
    const challengeDate = new Date(challenge.scheduledFor);
    const nowUTC = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 
                           now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
    const challengeDateUTC = Date.UTC(challengeDate.getUTCFullYear(), challengeDate.getUTCMonth(), 
                                    challengeDate.getUTCDate(), challengeDate.getUTCHours(), 
                                    challengeDate.getUTCMinutes(), challengeDate.getUTCSeconds());
    if (challengeDateUTC > nowUTC) {
      acc.upcoming.push(challenge);
    } else {
      acc.expired.push(challenge);
    }
    return acc;
  }, { upcoming: [], expired: [] });

  // Sort challenges by scheduledFor date
  const sortedUpcoming = upcoming.sort((a, b) => 
    new Date(a.scheduledFor) - new Date(b.scheduledFor)
  );
  
  const sortedExpired = expired.sort((a, b) => 
    new Date(b.scheduledFor) - new Date(a.scheduledFor)
  );

  const renderChallengeGrid = (challengeList) => (
    <div className="challenges-grid">
      {challengeList.map(challenge => (
        <div key={challenge.id} className="challenge-card">
          <div className="challenge-card-header">
            <h3>{challenge.title}</h3>
            <button 
              onClick={() => handleDelete(challenge.id)}
              className="delete-button"
              title="Delete Challenge"
              data-challenge-id={challenge.id}
              disabled={deletingIds.has(challenge.id)}
            >
              {deletingIds.has(challenge.id) ? '...' : '×'}
            </button>
          </div>
          {challenge.imageUrl && (
            <div className="challenge-image-container">
              <img 
                src={challenge.imageUrl} 
                alt={challenge.title}
                className="challenge-preview-image"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/placeholder-image.png';
                }}
                loading="lazy"
              />
            </div>
          )}
          <p className="scheduled-date">
            Scheduled for: {formatDate(challenge.scheduledFor)}
          </p>
          <p className="description">
            Bronze: {challenge.bronzeDescription}<br/>
            Silver: {challenge.silverDescription}<br/>
            Gold: {challenge.goldDescription}
          </p>
          <div className="badge-previews">
            {challenge.bronzeBadge?.imageUrl && (
              <div className="badge-preview">
                <img src={challenge.bronzeBadge.imageUrl} alt="Bronze Badge" />
                <span>Bronze</span>
              </div>
            )}
            {challenge.silverBadge?.imageUrl && (
              <div className="badge-preview">
                <img src={challenge.silverBadge.imageUrl} alt="Silver Badge" />
                <span>Silver</span>
              </div>
            )}
            {challenge.goldBadge?.imageUrl && (
              <div className="badge-preview">
                <img src={challenge.goldBadge.imageUrl} alt="Gold Badge" />
                <span>Gold</span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <ErrorBoundary>
      <div className="challenge-list">
        <h2>Upcoming Challenges</h2>
        {sortedUpcoming.length === 0 ? (
          <p className="no-challenges">No upcoming challenges scheduled.</p>
        ) : (
          renderChallengeGrid(sortedUpcoming)
        )}

        <h2 className="expired-header">Expired Challenges</h2>
        {sortedExpired.length === 0 ? (
          <p className="no-challenges">No expired challenges.</p>
        ) : (
          renderChallengeGrid(sortedExpired)
        )}
      </div>
    </ErrorBoundary>
  );
}

ChallengeList.propTypes = {
  challenges: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    scheduledFor: PropTypes.string.isRequired,
    bronzeDescription: PropTypes.string.isRequired,
    silverDescription: PropTypes.string.isRequired,
    goldDescription: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    bronzeBadge: PropTypes.shape({
      imageUrl: PropTypes.string
    }),
    silverBadge: PropTypes.shape({
      imageUrl: PropTypes.string
    }),
    goldBadge: PropTypes.shape({
      imageUrl: PropTypes.string
    })
  })).isRequired,
  onChallengeDeleted: PropTypes.func.isRequired
};

export default ChallengeList; 